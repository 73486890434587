.copyright {
    margin-top: 5px;
    font-size: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px
}

footer {
    clear: both;
    position: relative;
    height: 75px;
    margin-top: -75px;
}

.footer-div {
    display: block;  
    text-align: center; 
    background-color: rgb(50,50,50);
    width: 100%;
    z-index: 2;
}

.quicklinks {
    font-size: 12px
}

.social-buttons li a {
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    transition: all .3s;
    color: #fff;
    border-radius: 100%;
    outline: none;
    background-color: #212529;
    font-size: 15pt;
    margin: 10px;
    margin-bottom: 0;
}

.social-buttons li a:hover {
    background-color: #08613c;
}

.footer-contacts {
    height: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    padding-bottom: 10px;
}