.contact-row {
    flex-basis: 20%;
    padding: 45px;
}

.contacts-heading {
    font-weight: 700;
}

#contacts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    grid-auto-flow: dense;
}