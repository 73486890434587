.product-details {
    padding-top: 30px;
    padding-bottom: 15px;
    border-top: 1px solid grey;
    margin: 5px;
    margin-left: 10px;

}
.product-title {
    text-transform: uppercase;
    padding-top: 15px;
}
.product-image {
    max-width: 50vw;
}

.product-price {
    margin: 10px
}

.buy-btn {
    margin: 10px;
    display: inline;
}

.carousel-inner img {
    margin: auto;
  }

.product-description {
    margin: 5px;
}

.product-details > .carousel-inner {
    padding: 20px;
}