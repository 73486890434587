.product-catalogue {
    margin-top: 5px;
}

.product-list {
    height: 100%;
    border-right: 1px solid grey;
    padding-right: 10px;
    display: grid;
    justify-items: center;
    text-align: center;
}

.product-list-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 10pt;
    text-decoration: underline;
}

.product-list-ul li {
    margin-top: 10px;
    cursor: pointer;
}

.product-purchase-form {
    padding-top: 20px;
    padding-left: 20px;
    width: 90vw;
}

.form-check-input:checked {
    background-color: #0f717e;
    border-color: #0f717e;
}

.btn-primary {
    color: #fff;
    background-color: #0f717e;
    border-color: #0f717e;
}

.product-purchase-header {
    margin-left: 20px;
    margin-top: 20px;
}

.swedbank-logo {
    padding: 10px;
    display: inline;
    max-width: 200px;
}


.swedbank-info {
    display: inline-block;
}

.payment-method-radios {
    margin-top: 10px;
}

.product-category-selector {
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 5px;
}

.product-category-btn {
    color:rgba(255, 255, 255, 0.55);
    background-color: transparent;
    text-transform: uppercase;
    border-radius: 5px;
    border: none;

    width: 70vw;
    max-width: 400px;
    padding: 5px;
}

.hidden-category {
    display: none
}

.product-categories-list {
    margin-top: 20px;
}

.modal {
    z-index: 99999;
    top: 20vh;
}