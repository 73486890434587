body {
    background-color: rgb(41, 41, 41) !important;
    font-family: Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: rgb(155, 155, 155);
}

:root {
    --primary-color: #0f717e;
    --primary-color-text: #52b9c7;

    --primary-blue: #4b758f;
    --primary-blue-dark: #2b4e64;
    --primary-blue-light: #71afd5;
    --primary-blue-light-super: #b7d7ea;

}


.container {
    padding: 50px;
}


header.masthead .intro-text {
    padding-top: 100px;
    padding-bottom: 100px;
}

h6 {
    font-size: 14px;
}


.bg-light {
    background-color: rgb(41, 41, 41) !important;
}

.text-primary {
    color: #3d6663 !important;
}

a:hover {
    color: #ffffff;
}

a:hover {
    color: #ffffff;
}

a {
    color: #858585;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #4a8480;
    border-color: #389186;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #3f92a4 !important;
    border-color: #49baac !important;
    color: #fff;
}


html {
    height: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: 150px;
    font-family: Montserrat;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
}

body {
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;

}

#root {
    height: 100%;
    min-height: 100%;
}

.main-content {
    padding-top: 150px;
    padding-bottom: 100px
}

a {
    text-decoration: none;
}

.rs__event__item > div {
    background-color: var(--primary-blue) !important;
}

.rs__multi_day > div {
    background-color: var(--primary-blue) !important;
}

.schedulerButton {
    background-color: var(--primary-blue) !important;
}

.schedulerButton:hover {
    background-color: var(--primary-blue-light) !important;
}

.shedulerInstruction {
    background-color: var(--primary-blue-light-super) !important;
    color: black;
}

.schedulerButton.selected.btn.btn-primary {
    background-color: var(--primary-blue-dark) !important;
    border-color: #67e7f8;
}

.MuiButton-textPrimary {
    color: white !important;
    font-weight: bold !important;
}

.rs__today_cell > div > p {
    color: var(--primary-color-text) !important;
}

.css-m1a27h {
    margin: 20px;
}

.rs__cell > div > p {
    color: rgb(129, 129, 129) !important
}

.rs__outer_table {
    border: 2px solid rgb(105, 105, 105);
    border-radius: 20px;
}

.css-1edlcpn {
    border: 2px solid rgb(102, 102, 102);
    border-radius: 20px;
}

.login-form-div {
    width: 500px;
    padding: 20px;
    margin: auto
}

.MuiDialog-container {
    max-height: 50% !important;
    max-width: 90% !important;
    margin: auto;
    margin-top: 200px;
}

.rs__cell {
    border-color: rgb(109, 109, 109) !important;
}

.uppercase {
    text-transform: uppercase;
}


.studio-selector {
    display: grid;
    grid-column-gap: 3vh;
    padding: 10px;
    grid-auto-flow: column;
    align-self: center;
    overflow-x: scroll;
    align-items: center;
}


*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #888;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.scheudlerHeader {
    width: 100%;
    text-align: center;
    margin-bottom: 30px
}


.schedulerForm > * {
    background-color: rgb(41, 41, 41) !important;
    color: rgb(155, 155, 155);
    padding: 2rem;
    border: 3px solid grey;
    border-radius: 5%;
}

.schedulerFormButtons {
    display: grid;
    grid-template-columns: 1fr 1fr
}

.schedulerFormButtonsClose {
    justify-self: right !important;
}

.MuiDialog-container {
    max-height: 90% !important;
    max-width: 400px !important;
    max-width: 90% !important;
    background-color: transparent !important;
}

.shedulerInstructionDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.MuiDialog-container {
    background: transparent !important;
}

.MuiBackdrop-root {
    background: transparent !important;
}

.MuiPaper-root {
    background: transparent !important;
}

.MuiClockPicker-root {
    background-color: rgb(41, 41, 41) !important;
}

.additionalServicesDiv {
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    overflow-y: scroll;
    max-height: 8em;
}

.alert {
    margin-bottom: 0px;
    margin: 5px;
}

.inlineDiv {
    display: inline-block;
}

.gallery-item {
    cursor: pointer !important;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
}

.galleryItemExpanded {
    max-width: none !important;
    max-height: none !important;
}