.master-header {
  background-image: url('./img/sanik.jpg');
  padding-top: 135px;
  padding-bottom: 135px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50%;
  background-size: cover;
}



.header-text {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  display: block;
  text-align: center;
  text-shadow: 5px 5px black;
  font-weight: 700;
  color: white;
  background-position: 50%;
  background-size: cover;
  text-transform: uppercase;

  font-size: 75px;
  line-height: 75px;
}

@media only screen and (max-width: 768px) {
  .header-text {
    font-size: 45px;
    line-height: 45px;
  }
}


.payment-status {
  padding: 50px;
  text-align: center;
}