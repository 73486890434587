@media (min-width: 1150px) {
  .navbar-expand-custom {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-custom .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }
}

.navbar-nav li {
  margin-right: 12px;
  margin-left: 12px;
}
.nav2 li {
  margin-right: 20px;
}

@media (min-width: 768px) {
  .col-md-3 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media (min-width: 768px) {
  .col-md-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

#mainNav .navbar-toggler {
  font-size: 12px;
  right: 0;
  padding: 13px;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  background-color: #484848;
  font-family: Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.navbar-brand {
  display: flex;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar {
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 500;
}

.nt-logo {
  color: rgb(143,143,143) !important;
  text-decoration: none;
  font-family: "Amatic SC", cursive;
  font-size: 55px;
}

.navbar {
  background-color: #212121;
  border-bottom: 5px solid #191919;
}

.navbar-toggler {
  margin: 0px 10px;
  border: none;
}